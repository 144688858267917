<template>
  <div class="newProject">
    <div class="title">{{this.$route.params.action?'编辑项目':'新建项目'}}</div>
    <!-- <div class="toHome" @click="toHomePage">返回首页</div> -->
    <div>
      <el-form
        :model="createOrganization"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="createOrganization.name" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="industrial_classification_id">
          <el-select v-model="createOrganization.industrial_classification_id" placeholder="所属行业">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in listIndustrialClassification"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区" prop="province">
          <el-cascader
            v-model="createOrganization.province"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="createOrganization.address" placeholder="请输入详细地址(快递地址)"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="contact">
          <el-input v-model="createOrganization.contact" placeholder="请输入负责人"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="createOrganization.phone" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <div style="width:100%" class="flex_center_center">
          <div>
          <!-- @click="resetForm('ruleForm')" -->
          <el-button  v-if="!this.$route.params.action" @click="goReturn">取消</el-button>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >{{this.$route.params.action?'编辑项目':'新建项目'}}</el-button>
        </div></div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { user, dataCation, organization, account } from "@/utils/api";
import location from "@/utils/province_city_district.json";
import { isTelAvailable } from "../../utils/verification";
export default {
  name:"newRegister",
  data() {
    var checkPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    return {
      createOrganization: {
        name: "",
        contact: "",
        phone: "",
        address: "",
        industrial_classification_id: "",
        province_number: "",
        city_number: "",
        district_number: "",
        province: [],
      },
      province: [],
      rules: {
        name: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          {
            min: 1,
            max: 16,
            message: "长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
        industrial_classification_id: [
          { required: true, message: "请选择所属行业区域", trigger: "change" },
        ],
        province: [
          {
            type: "array",
            required: true,
            message: "请选择所在省/市/区",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请输入收货地址", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请输入负责人名称", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      // 行业列表
      listIndustrialClassification: {},
    };
  },

  components: {},

  computed: {},
  created() {
    this.editData();
  },
  mounted() {
    this.getlistIndustrialClassification();
    this.dataBackfill();
  },
  methods: {
    //点击立即创建按钮
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          delete this.createOrganization.province;
          if (!this.$route.params.action) {
            const res = await this.$http.post(
              user.createOrganization,
              this.createOrganization
            );
            if (res.code === 0) {
              this.$message.success("创建成功");
              await this.getPersonnelInfo();
            }else{
              this.$alert(res.message, '提示', {
          confirmButtonText: '确定',
            });
            }
          } else {
            const res = await this.$http.post(
              organization.editOrganization,
              this.createOrganization
            );
            if (res.code === 0) {
              this.$message.success("修改成功");
              await this.getPersonnelInfo();
            }
          }
        } else {
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取行业分页列表
    async getlistIndustrialClassification() {
      const res = await this.$http.get(dataCation.listIndustrialClassification);
      this.listIndustrialClassification = res.data;
    },
    // 修改省市区等数据
    editData() {
      if (location.length > 0) {
        location.forEach((item1) => {
          item1.label = item1.name;
          item1.value = item1.number;
          item1.children = item1.city;
          item1.children.forEach((item2) => {
            item2.label = item2.name;
            item2.value = item2.number;
            item2.children = item2.district;
            item2.children.forEach((item3) => {
              item3.label = item3.name;
              item3.value = item3.number;
            });
          });
        });
        this.options = location;
      }
    },
    updated() {
      this.editData();
    },
    // 级联选择器的下拉处理函数
    handleChange(value) {
      this.createOrganization.province_number = value[0];
      this.createOrganization.city_number = value[1];
      this.createOrganization.district_number = value[2];
    },
    // 点击返回首页
    toHomePage() {
      this.$router.push("homePage");
    },
    // 进行数据回填等操作
    dataBackfill() {
      if (!this.$route.params.action) {
        return false;
      } else {
        let project = this.$store.state.current_project.organization;
        let {
          name,
          contact,
          phone,
          address,
          industrial_classification_id,
          province_number,
          city_number,
          district_number,
        } = project;
        this.createOrganization = {
          name,
          contact,
          phone,
          address,
          industrial_classification_id,
          province_number,
          city_number,
          district_number,
        };
        this.createOrganization.province = [
          province_number,
          city_number,
          district_number,
        ];
      }
    },
    // 获取用户个人信息并更新当前项目
    async getPersonnelInfo() {
      const res = await this.$http.get(account.info);
      await this.$store.commit("setUserInfo", res.data);
      if (!this.$route.params.action) {
        // 设置最后一个项目为当前项目 否则 进行索引修改当前项目 进行设置项目
        // 进行当前项目更新 但是也还是需要更新数组
        let arr = this.$store.state.projectArr;
        let obj = {
          label: res.data.members[res.data.members.length - 1].organization.name,
          value: res.data.members[res.data.members.length - 1].organization.id,
        };
        arr.push(obj);
        this.$store.commit("setProjectArr", arr);
        this.$store.commit(
          "setCurrentProject",
          res.data.members[res.data.members.length - 1]
        );
      } else {
        // 进行当前项目更新 但是并不需要更新数组
        let current_project = this.$store.state.user_info.members.find(
          (item) => {
            return item.organization_id === this.$store.state.current_project.organization_id;
          }
        );
        this.$store.commit("setCurrentProject", current_project);
      }
      this.toHomePage()
    },
    // 点击取消返回上一页
    goReturn(){
      this.$router.go(-1)
    },

  },
};
</script>
<style lang='scss' scoped>
.el-form-item /deep/ .el-input__inner {
  width: 300px !important;
  background-color: #fff !important;
  color: #666666 !important;
}
.newProject {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 830px;
  height: 542px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  margin: 0px auto;
  top: 50%;
  left: 50%;
  margin-top: -271px;
  margin-left: -415px;
  .title {
    font-size: $fontSizeMap-20;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 28px;
    margin-top: 19px;
    margin-bottom: 50px;
  }
  .toHome {
    position: absolute;
    right: 64px;
    top: 21px;
    font-size: $fontSizeMap-18;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2379f2;
    line-height: 25px;
    cursor: pointer;
  }
}
</style>
